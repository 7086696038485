import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import YouTube from 'react-youtube';
export const _frontmatter = {
  "path": "/blog/building-cloudwatch-dashboards",
  "date": "18th January 2023",
  "title": "Building Cloudwatch dashboards with custom Drupal metrics",
  "summary": "In this video I detail the purpose and benefits of CloudWatch metrics in Drupal.",
  "author": "Karl Hepworth",
  "tag": "Case Study",
  "tagColor": "blue",
  "tags": [{
    "name": "metrics"
  }, {
    "name": "cloudwatch"
  }, {
    "name": "presentation"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`At DrupalSouth '22, I spoke about custom CloudWatch metrics & dashboards.`}</p>
    <p>{`During my talk, I covered:`}</p>
    <ul>
      <li parentName="ul">{`What metrics are, how they’re structured and why they’re helpful`}</li>
      <li parentName="ul">{`The purpose of CloudWatch metrics and how to generate metrics from logs and existing websites`}</li>
      <li parentName="ul">{`The value of having access to real-time metrics and how they benefit security and performance in Drupal, using alerting and notification systems to inform administrators of a situation`}</li>
    </ul>
    <p>{`Here's the recording:`}</p>

    <YouTube videoId="dd3AkR2DN28" opts={{
      height: '420',
      width: '100%',
      playerVars: {
        start: '979'
      }
    }} mdxType="YouTube" />
    <p>{`Please reach out on social media if you have any questions or request a demo below, and I can show you how you can use Cloudwatch dashboards for your applications.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      